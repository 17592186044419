import loadable from '@loadable/component';
import { WithLazyHydration } from '../../WithLazyHydration';

const NewsletterModuleParagraphLoadable = loadable(
  () => import('./NewsletterModuleParagraph'),
  {
    resolveComponent: components => components.NewsletterModuleParagraph
  }
);

export const NewsletterModuleParagraph = WithLazyHydration(
  NewsletterModuleParagraphLoadable,
  {
    name: 'NewsletterModuleParagraph'
  }
);
