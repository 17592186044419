import loadable from '@loadable/component';
import { WithLazyHydration } from '../../WithLazyHydration';

const DynamicPackageLoadable = loadable(() => import('./DynamicPackage'), {
  resolveComponent: components => components.DynamicPackage
});

export const DynamicPackage = WithLazyHydration(DynamicPackageLoadable, {
  name: 'DynamicPackage'
});
