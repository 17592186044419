import loadable from '@loadable/component';
import { WithLazyHydration } from '../../WithLazyHydration';

const HighVolumeHeroPackageLoadable = loadable(
  () => import('./HighVolumeHeroPackage'),
  {
    resolveComponent: components => components.HighVolumeHeroPackage
  }
);

export const HighVolumeHeroPackage = WithLazyHydration(
  HighVolumeHeroPackageLoadable,
  {
    name: 'HighVolumeHeroPackage'
  }
);
