import loadable from '@loadable/component';
import { WithLazyHydration } from '../../WithLazyHydration';

const ContentPackageLoadable = loadable(() => import('./ContentPackage'), {
  resolveComponent: components => components.ContentPackage
});

export const ContentPackage = WithLazyHydration(ContentPackageLoadable, {
  name: 'ContentPackage'
});
